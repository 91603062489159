import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import useWindowSize from '../../hooks/useWindowSize'
import { useState, useEffect } from 'react'
import ServicesImgWeb from '../../assets/img/cloud-services.js'
import ServicesMobile from '../../assets/img/Group10.png'

const CloudServices = () => {
  const windowSize = useWindowSize()
  const [isMobile, setMobile] = useState(false)

  useEffect(() => {
    if ((windowSize.width !== undefined && windowSize.width > 640)) {
			setMobile(false)
		} else {
			setMobile(true)
		}
  }, [windowSize]);

  return (
    <Styled>
      <Grid container justifyContent='center' spacing={0}>
        <Grid item xs={12}>
          <div align='center' className='title'>
            Servicios
          </div>
        </Grid>
        <Grid item xs={11}>
          <div  className='strategy'>
            Desde la estrategia, la migración y operación, hasta Mesa de Soporte N1 N2 y la Infraestructura con los grandes vendors del 
            mercado, tenemos todo para acompañarte en el desafío del cambio, la mejora y el crecimiento.
          </div>
        </Grid>

        <Grid item container justifyContent="center" xs={12}>
            { !isMobile && (<ServicesImgWeb />)}
            { isMobile && (
              <img width='100%' src={ServicesMobile} alt='Services imagen' />          
            )}
        </Grid>
      </Grid>
    </Styled>
  )
}

export default CloudServices

const Styled = styled.section`
  margin: 48px 0 86px;
  max-width: 1200px;
  width: 100%;
  @media (max-width: 640px){
    margin: 0 0 48px 0;
  }

  .title {
    color: #030144;
    font-family: 'Century Gothic';
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 32px;
  }

  .strategy {
    color: #030144;
    font-family: "Century Gothic";
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 60px;
  }

`
