import React from 'react'
import styled from 'styled-components'
import ContactForm  from '../landing/ContactForm'
import { Grid } from '@material-ui/core'
import CCoELogo from '../../assets/img/CCoE-logo.png'

const CloudInfo = () => {
  return (
    <Styled>
      <Grid container  
      direction="row"
      justifyContent="center"
      spacing={8} 
      className='grid-container'>
        <Grid  container alignContent="flex-start" xs={12} md={6}>
          <Grid item container justifyContent="flex-start" xs={12} className='logo'>
            <img width="450" height="182" src={CCoELogo} alt="ccoe logo" />
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <div className='text'>
              Creamos el centro de servicios cloud más completo de la región para brindarte todos los servicios y profesionales 
              certificados que necesitas para encontrar valor real y oportunidades para tu negocio al migrar y operar tu empresa en la Nube. 
            </div>
          </Grid>
        </Grid>
        <Grid item container justifyContent="center" xs={12} md={6}>
          <div className="contact">
            <ContactForm  page='Cloud' location={'Contacto Web'} 
              title={'Conoce nuestros casos de éxito y la propuesta del CCoE para tu empresa:'} 
              paddingTitle='0px' />
          </div>
        </Grid>
      </Grid>
    </Styled>
  )
}

export default CloudInfo

const Styled = styled.section`
  max-width: 1200px;
  margin: 48px 0;

  img {
    @media (max-width: 640px){
      width: 250px;
      height: auto;
    }
  }

  .grid-container {
    width: 100%;
    margin: 0;
  }

  .logo {
    display: flex;
    justify-content: flex-start;
    padding: 32px;
    @media (max-width: 640px){
      padding: 0 32px;
    }
  }

  .title{
    @media (max-width: 780px){
      text-align: start;
      padding: 0px;
    }
  }

  .title2{
    height: 40px;
    width: 500px;
    color: #000000;
    font-family: "Century Gothic";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    padding-top: 10px;
    @media (max-width: 780px){
      width: 300px;
      text-align: start;
    }
  }
  
  .linkAccess{
    @media (max-width: 780px){
      margin-top: 40px;
    }
  }
  
  .text {
    max-width: 100%;
    color: #030144;
    font-family: "Century Gothic";
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
    padding: 32px;
  }

  .contact {
    max-width: 100%;
    padding: 0 32px;
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;

    @media (max-width: 900px) {
        margin-left: 0px;
    }

    @media (max-width: 780px) {
      margin: 0px 0px 20px 0px;
      padding: 20px;
      width: 100%;
    }

    @media (max-width: 640px){
      h2 {
        font-size: 20px;
      }
      padding: 0;
    }

    button {
      background-color: #915CFD;
    }
 }
`
