import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CloudInfo from "../../components/ccoe/cloud-info";
import CloudServices from "../../components/ccoe/cloud-services";
import Benefits from "../../components/ccoe/benefits";
import Partners from "../../components/ccoe/partners";
import background from "../../assets/img/ccoe-background.png";
import backgroundMobile from "../../assets/img/ccoe-background-mobile.png";
import styled from "styled-components";


const Cloud = ({ location }) => (
  <Layout location={location}>
    <SEO title="Cloud" />
    <Styled>
      <CloudInfo />
      <CloudServices />
      <Benefits />
      <Partners />
    </Styled>
  </Layout>
)

const Styled = styled.div`
height: 100%;
min-height: 800px;
display:flex;
flex-direction: column;
align-items: center;
background-image: url("${background}");
background-size: cover;

@media (min-width: 780px){
  padding-top: 60px;
}

@media (max-width: 600px){
  background-image: url("${backgroundMobile}");
}

.alliances {
  margin-top: 100px;
  width: 100% !important;
}
`

export default Cloud